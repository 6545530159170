import React from "react"
import { graphql } from "gatsby"

import { Error as Page } from "../components/Error/Error"

export const query = graphql`
  query {
    page: sanityPageError {
      title
      content
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      link: _rawLink(resolveReferences: { maxDepth: 6 })
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
